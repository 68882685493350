window.Action_Alert_Id = function(message, class_alert, container_alert) {
    container_alert.empty().append(`
        <div class="alert ${class_alert} alert-dismissible fade show pr-4" role="alert">
            <div class="row">
                <div class="col">
                    <strong>${message}</strong>
                </div>
                <div class="col col-auto px-0">
                    <button style="border: 0px; background:none; color:white;" type="button" class="btn-close pr-1" data-bs-dismiss="alert">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
    `);
}

window.Show_Image = function(input_file, label_name_image, show_image, delete_image, container_alert)
    {
        input_file.on('change', function (event)
        {
            // Imagenes Permitidas
            const file_types =
            [
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/svg+xml"
            ];

            let file = event.target.files[0];

            if ((file.size / 1024) > 600)
            {
                window.Action_Alert_Id("El archivo no debe pesar más de 600 Kb. Intente con otro archivo.", "alert-danger", container_alert);
            }
            else
            {
                if (file_types.includes(file.type))
                {
                    label_name_image.text(file.name);

                    show_image.prop('hidden', false).prop('src', URL.createObjectURL(file));

                    delete_image.prop('hidden', false);

                    const reader_file = new FileReader();

                    reader_file.readAsDataURL(input_file.prop('files')[0]);

                    reader_file.onload = function ()
                    {
                        image_base64 = reader_file.result;
                    };

                    reader_file.onerror = function (error)
                    {
                        console.error("No se cargo la imagen en base64", error);
                        image_base64 = null;
                    };

                }
                else
                {
                    label_name_image.text("selecciona una imagen (.JPG, .JPEG, .PNG, .SVG)");
                    show_image.prop('src', "/assets/Img/defaults/image_no_found.svg");
                    image_base64 = null;
                }
            }
        });

        delete_image.on('click', function ()
        {
            label_name_image.text("selecciona una imagen (.JPG, .JPEG, .PNG, .SVG)");
            show_image.prop('src', "/assets/Img/defaults/image_no_found.svg");
            image_base64 = 'imagen_eliminada';
            delete_image.prop('hidden', true);
        });
    }
